<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">优惠券记录</div>
		<div class="form-search-box mt20">
			<a-form layout="inline">
				
				<a-form-item label="会员昵称">
					<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
				</a-form-item>
				
				<a-form-item label="会员手机号">
					<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
				</a-form-item>
				
				<a-form-item>
					<a-button @click="searchAct" type="primary">查询</a-button>
					<a-button @click="cancelAct" class="ml10">取消</a-button>
				</a-form-item>
			</a-form>
		</div>
		
		
		<div class="mt20">
			<a-layout>
				<a-layout-header class="layout-classes-header">
					<a-menu class="classes-menu" :defaultSelectedKeys="[type]" @click="selectMenu" mode="horizontal">
						<a-menu-item :key="1">获取记录</a-menu-item>
						<a-menu-item :key="-1">使用记录</a-menu-item>
					</a-menu>
				</a-layout-header>
				<a-layout-content class="layout-classes-content">
					<div class="pd30">
						<div class="wxb-table-white">
							<a-table rowKey="member_coupon_logs_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
							 :loading="loading">
								<div class="flex alcenter center" slot="member_id" slot-scope="record">
									{{record.member != null ? record.member.member_id : '-'}}
								</div>
							 
								<div  slot="member" slot-scope="member,record">
									<div v-if="member != null" class="flex alcenter center ">
										<img v-if="member.face!=null" :src="member.face" class="member-money-recharge-face" />
										<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-money-recharge-face" />
										<div class="ml5" style="text-align: left;">
											<div>{{member.nick_name}}</div>
											<div class="mt2">{{member.mobile}}</div>
										</div>
									</div>
									<div v-else>-</div>
								</div>
								
								<div class="flex alcenter center text-over4" slot="mobile" slot-scope="record">
									{{record.member != null ? record.member.mobile : '-'}}
								</div>
								
								<div class="text-center text-over4" slot="name" slot-scope="record">
									{{record.membercoupon != null ? record.membercoupon.name : '-'}}
								</div>
								
								<div class="text-center text-over4" slot="coupon_money" slot-scope="record">
									¥{{record.membercoupon != null ? record.membercoupon.coupon_money : '-'}}
								</div>
								
								<div class="text-center text-over4" slot="remarks" slot-scope="remarks,record">
									{{remarks ? remarks : '-'}}
								</div>
								
								<div class="text-center " slot="operator" slot-scope="record">
									<div v-if="record.operate_name && record.operate_mobile">
										<div class="ft14 cl-info">{{record.operate_name}}</div>
										<div class="ft12 cl-notice">({{record.operate_mobile}})</div>
									</div>
									<div v-else>-</div>
								</div>
								
								<!-- <template slot="action" slot-scope="record,index">
									<div class="flex center">
										<a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
											<a-menu slot="overlay" v-if="record.membercoupon.status==0">
												<a-menu-item>
													<a class="menu-act" href="javascript:;" @click="write_off(record)">
														<span>核销券</span>
													</a>
												</a-menu-item>
											</a-menu>
										</a-dropdown>
									</div>
								</template> -->
							</a-table>
						</div>
					</div>
				</a-layout-content>
			</a-layout>
		</div>
			
			
		
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data(){
			return {
				loading:false,
				search: {
					nick_name: "",
					mobile:"",
				},
				type: 1,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '会员编号',key: 'member_id',align: 'center',scopedSlots: {customRender: 'member_id'}},
					{title: '会员',dataIndex: 'member',align: 'center',scopedSlots: {customRender: 'member'}},
					{title: '手机号',key: 'mobile',align: 'center',scopedSlots: {customRender: 'mobile'}},
					{title: '类型',dataIndex: 'info',align: 'center',ellipsis: true},
					{title: '优惠券',key: 'name',align: 'center',scopedSlots: {customRender: 'name'}},
					{title: '券面值',key: 'coupon_money',align: 'center',scopedSlots: {customRender: 'coupon_money'}},
					{title: '时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					// {title: '备注信息',dataIndex: 'remarks',align: 'center',scopedSlots: {customRender: 'remarks'}},
					// {title: '操作人',key: 'operator',align: 'center',scopedSlots: {customRender: 'operator'}},
					// {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		methods:{
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getMemberCouponLogsList',{
					nick_name:this.search.nick_name,
					mobile:this.search.mobile,
					type:this.type,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			write_off(record){
				this.$confirm({
					title: '确定核销这个优惠券吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/writeOffCoupon', {
								member_id:record.member_id,
								member_coupon_id:record.member_coupon_id,
							}).then(res => {
								this.$message.success('核销成功',1,()=>{
									this.getLists();
								})
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					},
				});
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			selectMenu(e) {
				this.type = e.key;
				this.getLists();
			},
			searchAct(){
				this.$ref.mychild.getLists();
			},
			cancelAct() { 
				this.search = {
					nick_name: "",
					mobile:"",
				};
				this.searchAct();
			},
		}
	}
</script>

<style>
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
		min-height: 700px;
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
	
	.member-money-recharge-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
</style>